import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from '../components/Layout/Layout'
import ContentCard from '../components/Layout/ContentCard'
import SEO from "../components/seo"

export const query = graphql`
  query {
    allNodeArticle(
      sort: {fields: created, order: DESC},
      filter: {promote: {eq: true}}
    ) {
      edges {
        node {
          id
          title
          drupal_internal__nid
          displayDate: created(formatString: "MM/DD/Y")
          path {
            alias
          }
          field_teaser
          relationships {
            featuredImage: field_image {
              localFile {
                childImageSharp {
                  fixed(width: 100, height: 100, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }`

const ArticlesPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="Home" />
    <section className="container mx-auto px-8">
      <div className="page-title">Articles</div>
      {data.allNodeArticle.edges.map(article => (
        <>
          <ContentCard data={article.node} location={location} key={article.node.id} />
        </>
      ))}
    </section>
  </Layout>
)

ArticlesPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default ArticlesPage;
